<template>
  <div class="tab-pane fade" id="review" role="tabpanel" aria-labelledby="review-tab">
   <div class="course__review">
      <h3>Reviews</h3>
      <p>Gosh william I'm telling crikey burke I don't want no agro A bit of how's your father bugger all mate off his nut that, what a plonker cuppa owt to do</p>

      <div class="course__review-rating mb-50">
         <div class="row g-0">
            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4">
               <div class="course__review-rating-info grey-bg text-center">
                  <h5>5</h5>
                  <ul>
                     <li><a href="#"> <i class="icon_star"></i> </a></li>
                     <li><a href="#"> <i class="icon_star"></i> </a></li>
                     <li><a href="#"> <i class="icon_star"></i> </a></li>
                     <li><a href="#"> <i class="icon_star"></i> </a></li>
                     <li><a href="#"> <i class="icon_star"></i> </a></li>
                  </ul>
                  <p>4 Ratings</p>
               </div>
            </div>
            <div class="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-8">
               <div class="course__review-details grey-bg">
                  <h5>Detailed Rating</h5>
                  <div class="course__review-content mb-20">
                     <div class="course__review-item d-flex align-items-center justify-content-between">
                        <div class="course__review-text">
                           <span>5 stars</span>
                        </div>
                        <div class="course__review-progress">
                           <div class="single-progress" data-width="100%"></div>
                        </div>
                        <div class="course__review-percent">
                           <h5>100%</h5>
                        </div>
                     </div>
                     <div class="course__review-item d-flex align-items-center justify-content-between">
                        <div class="course__review-text">
                           <span>4 stars</span>
                        </div>
                        <div class="course__review-progress">
                           <div class="single-progress" data-width="30%"></div>
                        </div>
                        <div class="course__review-percent">
                           <h5>30%</h5>
                        </div>
                     </div>
                     <div class="course__review-item d-flex align-items-center justify-content-between">
                        <div class="course__review-text">
                           <span>3 stars</span>
                        </div>
                        <div class="course__review-progress">
                           <div class="single-progress" data-width="0%"></div>
                        </div>
                        <div class="course__review-percent">
                           <h5>0%</h5>
                        </div>
                     </div>
                     <div class="course__review-item d-flex align-items-center justify-content-between">
                        <div class="course__review-text">
                           <span>2 stars</span>
                        </div>
                        <div class="course__review-progress">
                           <div class="single-progress" data-width="0%"></div>
                        </div>
                        <div class="course__review-percent">
                           <h5>0%</h5>
                        </div>
                     </div>
                     <div class="course__review-item d-flex align-items-center justify-content-between">
                        <div class="course__review-text">
                           <span>1 stars</span>
                        </div>
                        <div class="course__review-progress">
                           <div class="single-progress" data-width="0%"></div>
                        </div>
                        <div class="course__review-percent">
                           <h5>0%</h5>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div class="course__comment mb-75">
         <h3>2 Comments</h3>

         <ul>
            <li>
               <div class="course__comment-box ">
                  <div class="course__comment-thumb float-start">
                     <img src="../../assets/img/course/comment/course-comment-1.jpg" alt="">
                  </div>
                  <div class="course__comment-content">
                     <div class="course__comment-wrapper ml-70 fix">
                        <div class="course__comment-info float-start">
                           <h4>Eleanor Fant</h4>
                           <span>July 14, 2022</span>
                        </div>
                        <div class="course__comment-rating float-start float-sm-end">
                           <ul>
                              <li><a href="#"> <i class="icon_star"></i> </a></li>
                              <li><a href="#"> <i class="icon_star"></i> </a></li>
                              <li><a href="#"> <i class="icon_star"></i> </a></li>
                              <li><a href="#"> <i class="icon_star"></i> </a></li>
                              <li><a href="#" > <i class="icon_star"></i> </a></li>
                           </ul>
                        </div>
                     </div>
                     <div class="course__comment-text ml-70">
                        <p>So I said lurgy dropped a clanger Jeffrey bugger cuppa gosh David blatant have it, standard A bit of how's your father my lady absolutely.</p>
                     </div>
                  </div>
               </div>
            </li>
            <li>
               <div class="course__comment-box ">
                  <div class="course__comment-thumb float-start">
                     <img src="../../assets/img/course/comment/course-comment-2.jpg" alt="">
                  </div>
                  <div class="course__comment-content">
                     <div class="course__comment-wrapper ml-70 fix">
                        <div class="course__comment-info float-start">
                           <h4>Shahnewaz Sakil</h4>
                           <span>July 17, 2022</span>
                        </div>
                        <div class="course__comment-rating float-start float-sm-end">
                           <ul>
                              <li><a href="#"> <i class="icon_star"></i> </a></li>
                              <li><a href="#"> <i class="icon_star"></i> </a></li>
                              <li><a href="#"> <i class="icon_star"></i> </a></li>
                              <li><a href="#"> <i class="icon_star"></i> </a></li>
                              <li><a href="#" class="no-rating"> <i class="icon_star"></i> </a></li>
                           </ul>
                        </div>
                     </div>
                     <div class="course__comment-text ml-70">
                        <p>David blatant have it, standard A bit of how's your father my lady absolutely.</p>
                     </div>
                  </div>
               </div>
            </li>
         </ul>
      </div>
      <div class="course__form">
         <h3>Write a Review</h3>
         <div class="course__form-inner">
            <form action="#">
               <div class="row">
                  <div class="col-xxl-6">
                     <div class="course__form-input">
                        <input type="text" placeholder="Your Name">
                     </div>
                  </div>
                  <div class="col-xxl-6">
                     <div class="course__form-input">
                        <input type="email" placeholder="Your Email">
                     </div>
                  </div>
                  <div class="col-xxl-12">
                     <div class="course__form-input">
                        <input type="text" placeholder="Review Title">
                     </div>
                  </div>
                  <div class="col-xxl-12">
                     <div class="course__form-input">
                        <div class="course__form-rating">
                           <span>Rating : </span>
                           <ul>
                              <li><a href="#"> <i class="icon_star"></i> </a></li>
                              <li><a href="#"> <i class="icon_star"></i> </a></li>
                              <li><a href="#"> <i class="icon_star"></i> </a></li>
                              <li><a href="#" class="no-rating" > <i class="icon_star"></i> </a></li>
                              <li><a href="#" class="no-rating" > <i class="icon_star"></i> </a></li>
                           </ul>
                        </div>
                        <textarea placeholder="Review Summary"></textarea>
                     </div>
                  </div>
               </div>
               <div class="row">
                  <div class="col-xxl-12">
                     <div class="course__form-btn mt-10 mb-55">
                        <button type="submit" class="e-btn">Submit Review</button>
                     </div>
                  </div>
               </div>
            </form>
         </div>
      </div>
   </div>
 </div>
</template>

<script>
export default {
   name:'ReviewTab'
};
</script>

