<template>
<section class="cta__area pb-150">
	<div class="container">
		<div class="cta__inner-3 p-relative grey-bg-2 pt-75 pb-75 fix">
			<div class="cta__shape-3">
				<img class="cta-2" src="../../assets/img/cta/cta-shape-1.png" alt="">
				<img class="cta-3" src="../../assets/img/cta/cta-shape-2.png" alt="">
			</div>
			<div class="row">
				<div class="col-xxl-12">
					<div class="cta__content text-center p-relative">
						<span>Coming June</span>
						<h3 class="cta__title-2">Built to stand out.</h3>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-xxl-12">
					<div class="cta__form grey-bg-2">
						<div class="cta__form-inner">
							<form action="#">
								<input type="text" placeholder="Your Name">
								<input type="email" placeholder="Your Email">
								<button type="submit" class="e-btn e-btn-6">Take A Free Course</button>
							</form>
						</div>
						<div class="cta__agree d-flex align-items-center">
							<input class="e-check-input" type="checkbox" id="e-agree">
							<label class="e-check-label" for="e-agree">By signing up you agree to our <a href="#"> Terms & Conditions</a></label>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
</template>

<script>
export default {
	name:'CtaArea'
};
</script>

