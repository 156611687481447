<template>
<div class="latest-comments mb-95">
   <h3>3 Comments</h3>
   <ul>
      <li>
         <div class="comments-box grey-bg">
            <div class="comments-info d-flex">
                  <div class="comments-avatar mr-20">
                        <img src="../../assets/img/blog/comments/comment-1.jpg" alt="">
                  </div>
                  <div class="avatar-name">
                     <h5>Eleanor Fant</h5>
                     <span class="post-meta"> July 14, 2022</span>
                  </div>
            </div>
            <div class="comments-text ml-65">
               <p>So I said lurgy dropped a clanger Jeffrey bugger cuppa gosh David blatant have it, standard A bit of how's your father my lady absolutely.</p>
               <div class="comments-replay">
                  <a href="#">Reply</a>
               </div>
            </div>
         </div>
      </li>
      <li class="children">
         <div class="comments-box grey-bg">
            <div class="comments-info d-flex">
                  <div class="comments-avatar mr-20">
                        <img src="../../assets/img/blog/comments/comment-1.jpg" alt="">
                  </div>
                  <div class="avatar-name">
                     <h5>Dominic</h5>
                     <span class="post-meta">April 16, 2022 </span>
                  </div>
            </div>
            <div class="comments-text ml-65">
               <p>David blatant have it, standard A bit of how's your father my lady absolutely.</p>
               <div class="comments-replay">
                  <a href="#">Reply</a>
               </div>
            </div>
         </div>
         <ul>
            <li class="children-2">
               <div class="comments-box grey-bg">
                  <div class="comments-info d-flex">
                        <div class="comments-avatar mr-20">
                              <img src="../../assets/img/blog/comments/comment-3.jpg" alt="">
                        </div>
                        <div class="avatar-name">
                           <h5>Von Rails</h5>
                           <span class="post-meta">April 18, 2022 </span>
                        </div>
                  </div>
                  <div class="comments-text ml-65">
                     <p>He nicked it get stuffed mate spend a penny plastered.!</p>
                     <div class="comments-replay">
                        <a href="#">Reply</a>
                     </div>
                  </div>
               </div>
            </li>
         </ul>
      </li>
   </ul>
  </div>
</template>

<script>
export default {
  name: "LatestComment",
};
</script>
