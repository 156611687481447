<template>
    <HeroArea/>

</template>

<script>

import HeroArea from '../components/HomeTwo/HeroArea.vue';



export default {
   name:'HomeTwo',
   components:{

       HeroArea,

   }
}
</script>
