<template>
<section class="hero__area hero__height d-flex align-items-center grey-bg-2 p-relative">
   <div class="hero__shape">
      <img class="hero-1-circle" src="../../assets/img/shape/hero/hero-1-circle.png" alt="">
      <img class="hero-1-circle-2" src="../../assets/img/shape/hero/hero-1-circle-2.png" alt="">
      <img class="hero-1-dot-2" src="../../assets/img/shape/hero/hero-1-dot-2.png" alt="">
   </div>
   <div class="container">
      <div class="hero__content-wrapper mt-90">
         <div class="row align-items-center">
            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
               <div class="hero__content p-relative z-index-1">
                  <h3 class="hero__title">
                     <span>Access 2700+</span>
                     <span class="yellow-shape">Online <img src="../../assets/img/shape/yellow-bg.png" alt="yellow-shape"> </span> 
                     Tutorial From Top Instructor.</h3>
                     <p>Meet university,and cultural institutions, who'll share their experience.</p>
                     <router-link to="/courses" class="e-btn">view all course</router-link>
               </div>
            </div>
            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
               <div class="hero__thumb d-flex p-relative">
                  <div class="hero__thumb-shape">
                     <img class="hero-1-dot" src="../../assets/img/shape/hero/hero-1-dot.png" alt="">
                     <img class="hero-1-circle-3" src="../../assets/img/shape/hero/hero-1-circle-3.png" alt="">
                     <img class="hero-1-circle-4" src="../../assets/img/shape/hero/hero-1-circle-4.png" alt="">
                  </div>
                  <div class="hero__thumb-big mr-30">
                     <img src="../../assets/img/hero/hero-1.jpg" alt="">
                     <div class="hero__quote hero__quote-animation">
                        <span>Tomorrow is our</span>
                        <h4>“When I Grow Up” Spirit Day!</h4>
                     </div>
                  </div>
                  <div class="hero__thumb-sm mt-50 d-none d-lg-block">
                     <img src="../../assets/img/hero/hero-sm-1.jpg" alt="">
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</section>
</template>

<script>
export default {
  name: "HeroSection",
};
</script>